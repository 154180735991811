<template>
    <div v-if="rows.length">
        <h5>{{ rows[rows.length - 1].filed_at | nibnut.date("MMM dd") }}</h5>
        <data-table
            id="watchman-computers"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-totals="false"
            :searchable="false"
            :clickable="false"
            class="non-sticky"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                {{ row.os_version_number }}
                <div
                    v-if="row.os_version_number_max"
                    class="text-small text-gray"
                >
                    <strong>{{ $root.translate("Max supported") }}:</strong> {{ row.os_version_number_max }}
                </div>
                <div>
                    <strong>{{ $root.translate("Age") }}:</strong> <span v-if="!!age_for_row(row)">{{ age_for_row(row) }}</span><span v-else class="text-gray">{{ $root.translate('unknown') }}</span>
                </div>
                <div
                    v-if="!!row.applecare_expires"
                    :class="{ 'text-error': date_is_past(row.applecare_expires) }"
                >
                    <strong>{{ $root.translate("Applecare expires") }}:</strong> {{ row.applecare_expires | nibnut.date }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'os_version_number'">
                    {{ row.os_version_number }}
                    <div
                        v-if="row.os_version_number_max"
                        class="text-small text-gray"
                    >
                        <strong>{{ $root.translate("Max supported") }}:</strong> {{ row.os_version_number_max }}
                    </div>
                </div>
                <span v-else-if="field === 'birth_date'">
                    <span v-if="!!age_for_row(row)">{{ age_for_row(row) }}</span>
                    <span v-else class="text-gray">{{ $root.translate('unknown') }}</span>
                </span>
                <span v-else-if="field === 'applecare_expires'">
                    <span
                        v-if="!!row.applecare_expires"
                        :class="{ 'text-error': date_is_past(row.applecare_expires) }"
                    >
                        {{ row.applecare_expires | nibnut.date }}
                    </span>
                    <span class="text-gray">{{ $root.translate('n/a') }}</span>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import parseISO from "date-fns/parseISO"
import intervalToDuration from "date-fns/intervalToDuration"
import formatDuration from "date-fns/formatDuration"
import isPast from "date-fns/isPast"

import is_report from "./IsReport"

export default {
    name: "WatchmanReport",
    mixins: [is_report],
    computed: {
        state_identifier () {
            return "watchman-report"
        }
    },
    methods: {
        age_for_row (row) {
            if(row && row.birth_date) {
                const duration = intervalToDuration({
                    start: parseISO(row.birth_date),
                    end: new Date()
                })

                return formatDuration(
                    duration,
                    { format: ["years", "months"] }
                )
            }

            return ""
        },
        date_is_past (date_string) {
            return isPast(parseISO(date_string))
        }
    },
    data () {
        return {
            columns: {
                name: { label: "Computer", sort: null },
                last_user: { label: "User", sort: null },
                platform: { label: "Platform", sort: null },
                os_version_number: { label: "OS", sort: null, cell_class: "no-wrap" },
                birth_date: { label: "Age", sort: null, cell_class: "no-wrap" },
                applecare_expires: { label: "Applecare", sort: null }
            },

            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
